//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SectionIntro',
  props: {
    sectionEyebrow: {
      type: String,
      default: '',
    },
    sectionTitle: {
      type: String,
      default: '',
    },
    sectionParagraph: {
      type: String,
      default: '',
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.$props.link ? 'BaseLink' : 'div',{tag:"component",staticClass:"content-card",class:{ 'content-card--hero grid': _vm.$props.hero },attrs:{"path":_vm.$props.link},on:{"click":function($event){return _vm.$emit('onLinkClick', _vm.$props)}}},[(_vm.$props.image)?_c('BaseImage',_vm._b({staticClass:"content-card__image",class:{
        'content-card__image--hero': _vm.$props.hero,
        'content-card__image--hero--reverse': _vm.$props.hero && _vm.$props.reverse,
        'zoom-in-transition': _vm.$props.link,
      }},'BaseImage',_vm.$props.image,false)):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-card__body",class:{
        'content-card__body--hero': _vm.$props.hero,
        'content-card__body--hero--reverse': _vm.$props.hero && _vm.$props.reverse,
      }},[(_vm.$props.tag && _vm.$props.tag.text)?_c('BaseTag',{staticClass:"content-card__body__tag",attrs:{"variant":_vm.$props.tag.variant}},[_vm._v("\n        "+_vm._s(_vm.$props.tag.text)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.$props.eyebrow)?_c('BaseText',{staticClass:"content-card__body__eyebrow",attrs:{"tag":"span","variant":"meta"}},[_vm._v("\n        "+_vm._s(_vm.$props.eyebrow)+"\n      ")]):_vm._e(),_vm._v(" "),_c('BaseText',{staticClass:"content-card__body__title",attrs:{"tag":"h3","variant":_vm.titleVariant}},[_vm._v("\n        "+_vm._s(_vm.$props.title)+"\n      ")]),_vm._v(" "),_c('BaseText',{staticClass:"content-card__body__text",attrs:{"tag":"p","variant":_vm.textVariant}},[_vm._v("\n        "+_vm._s(_vm.$props.text)+"\n      ")]),_vm._v(" "),(_vm.$props.date)?_c('div',{staticClass:"content-card__body__date-container",class:{ 'content-card__body__date-container--hero': _vm.$props.hero }},[_c('BaseIcon',{attrs:{"name":"calendar","width":"28","height":"36"}}),_vm._v(" "),_c('BaseText',{staticClass:"content-card__body__date",class:{ 'content-card__body__date--hero': _vm.$props.hero },attrs:{"tag":"span","variant":_vm.dateVariant}},[_vm._v("\n          "+_vm._s(_vm.$props.date)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.$props.button)?_c('BaseButton',{attrs:{"secondary":""}},[_vm._v("\n        "+_vm._s(_vm.$props.button)+"\n      ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ContentCard',
  props: {
    eyebrow: {
      type: String,
      default: '',
    },
    tag: {
      type: [Object, null],
      default: () => null,
      validator: (val) => val === null || ('variant' in val && 'text' in val),
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    image: {
      type: [Object, null],
      default: () => null,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
    hero: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    button: {
      type: String,
      default: '',
    },
  },
  computed: {
    titleVariant() {
      return this.$props.hero ? 'h3' : 'h5-medium';
    },
    textVariant() {
      return this.$props.hero ? 'h3' : 'body-regular';
    },
    dateVariant() {
      return this.$props.hero ? 'body-medium' : 'micro-header';
    },
  },
};

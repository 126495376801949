//
//
//
//
//
//
//
//

export default {
  name: 'BaseTag',
  props: {
    variant: {
      type: String,
      default: 'dark',
      validator: (variant) => ['dark', 'light', 'white'].includes(variant),
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '@/mixins/global.js';
import ContentCard from '@/components/molecules/content-card/ContentCard';
import SectionIntro from '@/components/molecules/section-intro/SectionIntro';

export default {
  name: 'RelatedContent',
  components: {
    ContentCard,
    SectionIntro,
  },
  mixins: [global],
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagePath: this.$route.path,
    };
  },
  methods: {
    getFormattedCardData(block) {
      const formattedData = {};
      if (block?.__typename === 'ComponentInterlinksWhitepaperLink') {
        formattedData.title = block?.whitepaper?.title;
        formattedData.text = block?.whitepaper?.subtitle;
        formattedData.image = {
          url: block?.whitepaper?.thumbnailImage?.url,
          alternativeText: block?.whitepaper?.title,
        };
        formattedData.link = `/resources/whitepapers/${block?.whitepaper?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksWebinarLink') {
        formattedData.title = block?.webinar?.title;
        formattedData.text = block?.webinar?.subtitle;
        formattedData.image = {
          url: block?.webinar?.image?.url,
          alternativeText: block?.webinar?.title,
        };
        formattedData.link = `/resources/webinars/${block?.webinar?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksArticleLink') {
        formattedData.title = block?.article?.title;
        formattedData.text = block?.article?.subtitle;
        formattedData.image = {
          url: block?.article?.thumbnailImage?.url,
          alternativeText: block?.article?.title,
        };
        formattedData.link = `/resources/articles/${block?.article?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksCasestudyLink') {
        formattedData.title = block?.case_study?.title;
        formattedData.text = block?.case_study?.subtitle;
        formattedData.image = {
          url: block?.case_study?.thumbnailImage?.url,
          alternativeText: block?.case_study?.thumbnailImage?.alternativeText,
        };
        formattedData.link = `/case-studies/${block?.case_study?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksSolutionLink') {
        formattedData.title = block?.solution?.title;
        formattedData.text = block?.solution?.subtitle;
        formattedData.image = {
          url: block?.solution?.thumbnailImage?.url,
          alternativeText: block?.solution?.thumbnailImage?.alternativeText,
        };
        formattedData.link = `/solutions/${block?.solution?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksTopicLink') {
        formattedData.title = block?.topic?.title;
        formattedData.text = block?.topic?.subTitle;
        if (block?.topic?.thumbnailImage) {
          formattedData.image = {
            url: block?.topic?.thumbnailImage?.url,
            alternativeText: block?.topic?.thumbnailImage?.alternativeText,
          };
        }

        formattedData.link = `/topics/${block?.topic?.slug}`;
      } else if (block?.__typename === 'ComponentInterlinksDynamicPagesLink') {
        formattedData.title = block?.dynamic_page?.metaTitle;
        formattedData.text = block?.dynamic_page?.subTitle;
        formattedData.image = {
          url: block?.dynamic_page?.thumbnailImage?.url,
          alternativeText: block?.dynamic_page?.thumbnailImage?.alternativeText,
        };
        formattedData.link = block?.dynamic_page?.ParentPage
          ? `${this.buildParentUrlPath(block?.dynamic_page?.ParentPage)}/${
              block?.dynamic_page?.Slug
            }`
          : `/${block?.dynamic_page?.Slug}`;
      }

      // add fallback image
      if (!formattedData?.image?.url) {
        formattedData.image = {
          url: '/images/template/thumbnail-fallback.svg',
          alternativeText: block?.dynamic_page?.metaTitle,
        };
      }

      return formattedData;
    },
    handleRelatedLinkClick(relatedLink = null) {
      if (relatedLink?.link) {
        const eventObj = {
          event: 'related_link_clicked',
          page_path: this.pagePath,
          related_link_url: relatedLink?.link,
          related_link_type: this.getRelatedLinkType(relatedLink?.link),
        };

        this.$gtm.push(eventObj);
      }
    },
    getRelatedLinkType(url = '') {
      if (url.includes('/articles/')) return 'ArticleLinks';
      if (url.includes('/webinars/')) return 'WebinarLinks';
      if (url.includes('/topics/')) return 'TopicLinks';
      if (url.includes('/whitepapers/')) return 'WhitepaperLinks';
      if (url.includes('/case-studies/')) return 'CasestudyLinks';
      if (url.includes('/solutions/')) return 'SolutionLinks';

      return 'PageLinks';
    },
  },
};

import { render, staticRenderFns } from "./ContentCard.vue?vue&type=template&id=3803a5b9&scoped=true&"
import script from "./ContentCard.vue?vue&type=script&lang=js&"
export * from "./ContentCard.vue?vue&type=script&lang=js&"
import style0 from "./ContentCard.vue?vue&type=style&index=0&id=3803a5b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3803a5b9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,BaseTag: require('/usr/src/web-www/components/atoms/base-tag/BaseTag.vue').default,BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default,BaseIcon: require('/usr/src/web-www/components/atoms/base-icon/BaseIcon.vue').default,BaseButton: require('/usr/src/web-www/components/atoms/base-button/BaseButton.vue').default})

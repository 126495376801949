import { render, staticRenderFns } from "./SectionIntro.vue?vue&type=template&id=42af4468&scoped=true&"
import script from "./SectionIntro.vue?vue&type=script&lang=js&"
export * from "./SectionIntro.vue?vue&type=script&lang=js&"
import style0 from "./SectionIntro.vue?vue&type=style&index=0&id=42af4468&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42af4468",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default})
